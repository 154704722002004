import React, { Component } from 'react';
import './App.css';

import { GoogleLogin } from 'react-google-login';
import { BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';

import IndexLandingPage from './components/IndexLandingPage';
import Input from './components/Input';
import InputSubmitted from './components/InputSubmitted';

import HostLandingPage from './components/HostLandingPage';
import HostInput from './components/HostInput';
import HostInputSubmitted from './components/HostInputSubmitted';
import HostEvent from './components/HostEvent';
import HostEventUpdated from './components/HostEventUpdated';

import OwnerDashboard from './components/OwnerDashboard';
class App extends Component {

  constructor(props) {
    super(props);
    this.state = { redirect: null, isAuthenticated: false, userProfile: null };
  }

  logout = () => {
    this.setState({ redirect: null, isAuthenticated: false, userProfile: null });
  };

  onSuccessHost = (googleUser) => {
    var token = googleUser.getAuthResponse().id_token;

    var googleProfile = googleUser.getBasicProfile();
    var userProfile = { givenName: googleProfile.getGivenName(),
                        familyName: googleProfile.getFamilyName(),
                        email: googleProfile.getEmail(),
                        imageUrl: googleProfile.getImageUrl(),
                        token: token };

    console.log(userProfile.givenName + ' ' + userProfile.familyName);
    console.log(userProfile.email);
    console.log(userProfile.imageUrl);
    console.log("ID Token: " + userProfile.token);

    this.setState({ redirect: "/host-input", isAuthenticated: true, userProfile: userProfile });
  }

  onSuccessGuest = (googleUser) => {
    var token = googleUser.getAuthResponse().id_token;

    var googleProfile = googleUser.getBasicProfile();
    var userProfile = { givenName: googleProfile.getGivenName(),
                        familyName: googleProfile.getFamilyName(),
                        email: googleProfile.getEmail(),
                        imageUrl: googleProfile.getImageUrl(),
                        token: token };

    console.log(userProfile.givenName + ' ' + userProfile.familyName);
    console.log(userProfile.email);
    console.log(userProfile.imageUrl);
    console.log("ID Token: " + userProfile.token);

    this.setState({ redirect: "/input", isAuthenticated: true, userProfile: userProfile });

  }

  onFailure = (error) => {
    console.log(error);
  }

  render() {


    let findTestSite = (
      <div>
        <br></br>
        <div className="test-info font-mont"> Have you gotten a COVID-19 test yet? </div><br></br>

        <a className="test-link font-mont" target="_blank" href="https://www.pixel.labcorp.com/at-home-test-kits/covid-19-test" rel="noopener noreferrer">Get a COVID-19 test shipped to you (select "Congregate Setting" for question 3)</a> 
        <br></br><br></br>
        <a className="test-link font-mont" target="_blank" href="https://www.phila.gov/covid-testing-sites/#/" rel="noopener noreferrer">Or view list of testing sites in Philadelphia</a>
        <br></br><br></br>

      </div>
    );

    let googleGuestButton = (
      <div className="sign-in-button-div">
        {findTestSite}
        <br></br>

        <GoogleLogin
          clientId="237303754823-mojdea641as2oks5cp65ki4f7hk64suv.apps.googleusercontent.com"
          buttonText="Have Test Result? Sign In with Google to RSVP"
          prompt="select_account"
          onSuccess={this.onSuccessGuest}
          onFailure={this.onFailure}
        />
      </div>
    );


    let googleHostButton = (
      <div className="sign-in-button-div">
        <br></br><br></br>
        
        <GoogleLogin
          clientId="237303754823-mojdea641as2oks5cp65ki4f7hk64suv.apps.googleusercontent.com"
          buttonText="Sign In with Google as a Host"
          prompt="select_account"
          onSuccess={this.onSuccessHost}
          onFailure={this.onFailure}
        />
      </div>
    );

    console.log("this.state.isAuthenticated: "+this.state.isAuthenticated);
    console.log("this.state.redirect: "+this.state.redirect);


    if (this.state.isAuthenticated && this.state.redirect) {
      // redirecting to input
      return (
        <Router>
          <Route exact path="/input" render={props => 
            (<Input {...props} userProfile={this.state.userProfile}/>)}/>
          <Route exact path="/host-input" render={props => 
            (<HostInput {...props} userProfile={this.state.userProfile}/>)}/>
          <Redirect to={this.state.redirect} />
        </Router>
      );

    }

    return (

      <Router>
        <Switch>
          {/* Everett area*/}
          <Route exact path="/owner-dashboard" render = {props => (<OwnerDashboard {...props} userProfile ={this.state.userProfile}/>)}/>
          {/* Guest area */}

          <Route exact path="/" render={(props) => (
            <div className="App">
              <br/><br/><br/>
              <IndexLandingPage>
                {googleGuestButton}
              </IndexLandingPage>
            </div>
          )} />
          
          <Route exact path="/input" render={props => 
            (<Input {...props} userProfile={this.state.userProfile}/>)}/>


          {/* Host area */}

          <Route exact path="/host" render={(props) => (
            <div className="App">
              <br/><br/><br/>
              <HostLandingPage>
                {googleHostButton}
              </HostLandingPage>
            </div>
          )} />

          <Route exact path="/host-input" render={props => 
            (<HostInput {...props} userProfile={this.state.userProfile}/>)}/>


          <Route exact path="/host-event/:id" render={props => 
            (<HostEvent {...props} userProfile={this.state.userProfile}/>)}/>

          <Route path="/input-submitted" component={InputSubmitted}/>
          <Route path="/host-input-submitted" component={HostInputSubmitted}/>
          <Route path="/host-event-updated" component={HostEventUpdated}/>

          
        </Switch>
      </Router>

    );

   }

}


export default App;
