import React, { Component } from 'react';

class InputSubmitted extends Component {

  render () {
    return (

      <div className="wrapper_centering">
        <div className="container_centering">
          <div className="container">
              <div className="row justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="main_title_1">
                    <br/><br/>
                    <h3>Thank you!</h3>
                    <p>We've stored your info. If you have any updates before the event, you may re-submit the form.</p>
                    <p><strong><a className='test-link' href='../'>&larr; Back to home</a></strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>


    );
  }
}

export default InputSubmitted 