import React from 'react';


class OwnerDashboard extends React.Component {


  constructor(props) {
    super(props);
    
    //localstorage to keep the info upon page refresh :)
    //CHANGE THIS VARIABLE TO CHANGE COLUMN NAMES, AS WELL AS THE RETURN STATEMENT IN THE RENDER METHOD AND THE API GET-TOTAL-EVENTS
    this.columns = ["Event Name", "Host Email", "Start Time", "End Time", "RSVP Due Time", "Total Count", "Tested COVID-19 Negative", "Tested COVID-19 Positive", "Test Results Not Submitted"]
    this.state = {
      userProfile: JSON.parse(localStorage.getItem('userProfile')) || null,
      isAuthenticated: false,
      columnNames: [...this.columns],
      dragOver: "",
      sortBy: "Start Time"
    }

    this.userProfile = { // default test profile
      givenName: "Test",
      familyName: "",
      email: "Test@test.com",
      imageUrl: "",
      token: ""
    };


    if (window.location.host.startsWith("localhost")) {
      this.baseURL = "http://localhost:8080";
    } else if(window.location.host.startsWith("deacon-testing")) {
      this.baseURL = "https://deacon-testing.ue.r.appspot.com";
    } else {
      this.baseURL = "https://deacon.ue.r.appspot.com";
    }

    if (this.props.userProfile!=null) {

      this.state = { userProfile: this.props.userProfile };
      localStorage.setItem('userProfile', JSON.stringify(this.props.userProfile));

      this.userProfile = this.props.userProfile;

    } else if (this.state.userProfile!=null) {

      this.userProfile = this.state.userProfile;

    }
    var email = this.userProfile.email;
    console.log(email);
    console.log(email.split('@')[1]);
    if(email==='everett@fareist.com' || email==='solomonlist11@gmail.com' || email==='dsvessal@gmail.com' ||email ==='everett@thedeaconphl.com' || email.split('@')[1]==='groveflow.com'){
      this.state = {
        userProfile: JSON.parse(localStorage.getItem('userProfile')) || null,
        isAuthenticated: true,
        columnNames: [...this.columns],
        dragOver:"",
        sortBy:"Start Time"
      }
    }
    //console.log("this.userProfile.token: "+this.userProfile.token);

  }


  fetchColumnOrdering(){
    var formBody = new URLSearchParams();
    formBody.append("host_email", this.userProfile.email);
    formBody.append("token", this.userProfile.token);

    const requestOptions = {
      method:'POST',
      headers: {"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"},
      body:formBody
    }

    fetch(this.baseURL+'/get-user-ordering', requestOptions)
      .then(res=>res.json())
      .then(
        (results) =>{
          if(results.ordering!=="default"){
            try{
              let tempCols = []
              for(var i of results.ordering.split(',')){
                tempCols.push(this.state.columnNames[Number(i)])
              }
              this.setState({columnNames:tempCols});
            } catch(error){
              this.setState({columnError:error});
            }
          }
        }, (error) => {
          console.log("Error:"+error);
        }
      );
  }
  //sends post request to update the ordering associated with the current email
  updateOrdering(newOrder){
    //note: newOrder will be passed in as the current column listing, we need to transcribe this to an integer.
    var formBody = new URLSearchParams();
    formBody.append("host_email", this.userProfile.email);
    formBody.append("token", this.userProfile.token);
    var toTranscribe = '';
    for(var i of newOrder){
      //console.log(i);
      toTranscribe = toTranscribe+','+this.columns.indexOf(i).toString();
    }
    console.log(toTranscribe.substring(1, toTranscribe.length));
    formBody.append("ordering", toTranscribe.substring(1, toTranscribe.length));
    const requestOptions = {
      method:'POST',
      headers: {"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"},
      body:formBody
    }

    fetch(this.baseURL+'/update-user-ordering', requestOptions).catch((error)=>{
      console.log("Error"+error);
    });
  }
  fetchEventInfo() {

    var formBody = new URLSearchParams();
    formBody.append("host_email", this.userProfile.email);
    formBody.append("token", this.userProfile.token);
    

    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
      body: formBody
    }

    // https://reactjs.org/docs/faq-ajax.html

    fetch(this.baseURL + '/get-total-event-info', requestOptions)
      .then(res => res.json())
      .then(
        (results) => {

          //console.log("results: " + JSON.stringify(results));

          if (results.error) {
            console.log("results.error: "+ results.error);
          } else {
            this.setState({
              upcomingEvents: results.upcomingEvents
            })
          }

        },
        (error) => {
          console.log("Error:"+error);
        }
      );

  }

  componentDidMount() {

    // get event info
    this.fetchEventInfo();
    
    //get column ordering info
    this.fetchColumnOrdering();
    // get test results

    var formBody = new URLSearchParams();
    formBody.append("token", this.userProfile.token);
    formBody.append("host_email", this.userProfile.email);
    

    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
      body: formBody
    }
    // https://reactjs.org/docs/faq-ajax.html
    fetch(this.baseURL + '/get-total-covid-tests', requestOptions)
      .then(res => res.json())
      .then(
        (results) => {

          //console.log("results: " + JSON.stringify(results));
          
          if (results.error) {
            //console.log("results.error: "+ results.error);
            this.setState({
              testsLoaded: false,
              testsError: results.error
            });
          } else {
            
            this.setState({
              testsLoaded: true,
              testsItems: results.guestTests
            });
          }

        },
        (error) => {
          console.log("Error:"+error);
          this.setState({
            testsLoaded: false,
            testsError: error
          });
        }
      );


  
  }

  render () {


    // set up table to render

    
    //console.log(this.state.testsItems);
    //console.log(this.state);
    let eventRowsToRender = [];
    console.log(this.state);
    if (this.state.testsError || this.state.isAuthenticated===false || this.state.columnError) {
      // error (e.g. token expired)
      return ( 
      <div className="wrapper_centering">
        <div className="container_centering">
          <div className="container">
            <div className="row justify-content-between">
              <div className="d-flex align-items-center">
                <div className="main_title_1">
                  <h1 className="main_question">Sorry, you're not authorized to view this page.</h1> 
                  <br></br>
                  <p><strong><a className='test-link' href='../'>&larr; Back to home</a></strong></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
    } else if (this.state.upcomingEvents && this.state.testsItems) {
      //console.log('this is running!!!');
      //we will still save the rows as an option to render, but this is mainly just to do the counts.
      //console.log(this.state.testsItems);
      //console.log(this.state.testsItems);
      eventRowsToRender = this.state.testsItems.map((eventContainer, index) => {
        //console.log(eventContainer);
        var eventTests = eventContainer[1];
        var countNegatives = 0;
        var countPositives = 0;
        var countNotSubmitted = 0;
        for(let i = 0; i<eventTests.length;i++){
          var testResult = eventTests[i].testResult;
          if(testResult==null){
              countNotSubmitted++;
          } else if(testResult === "Negative"){
            countNegatives++;
          } else if(testResult === "Positive"){
            countPositives++;
          } 
        }
        let currentEvent = this.state.upcomingEvents[eventContainer[0]];
        //console.log(currentEvent);
        if(currentEvent){
          //CHANGE THIS IF YOU CHANGE COLUMN NAMES
          return ({"Event Name": currentEvent.eventName, "Host Email": currentEvent.hostEmail, "Start Time": currentEvent.eventStartTime, "End Time": currentEvent.eventEndTime, "RSVP Due Time": currentEvent.rsvpDueTimeLocal, "Total Count": countNegatives+countPositives+countNotSubmitted, "Tested COVID-19 Negative": countNegatives, "Tested COVID-19 Positive": countPositives, "Test Results Not Submitted": countNotSubmitted})
        } else {
          // disable warnings for this
          // eslint-disable-next-line
          return;
        }
      });
      if(this.state.sortBy==="Start Time"){
        let monthMap = {"January":1, "February":2, "March":3, "April":4, "May":5, "June":6, "July":7, "August":8, "September":9, "October":10, "November":11, "December":12};
        let AMPM = {"AM":0, "PM":0.5};
        let tempA, clockA;
        let tempB, clockB;
        eventRowsToRender.sort(function(a,b){
          tempA = a["Start Time"].split(' ');
          tempB = b["Start Time"].split(' ');
          clockA = tempA[4].split(':');
          clockB =tempB[4].split(':');
          //console.log((Number(tempA[3])-Number(tempB[3]))*365+(monthMap[tempA[1]]-monthMap[tempB[1]])*30+(Number(tempA[2].substring(0, tempA[2].length-1))-Number(tempB[2].substring(0, tempB[2].length-1)))+(AMPM[tempA[5]]-AMPM[tempB[5]])+(Number(clockA[0]+clockA[1]/60)-Number(clockB[0]+clockB[1]/60))/12);
          //compares time
          return (Number(tempA[3])-Number(tempB[3]))*365+(monthMap[tempA[1]]-monthMap[tempB[1]])*30+(Number(tempA[2].substring(0, tempA[2].length-1))-Number(tempB[2].substring(0, tempB[2].length-1)))+(AMPM[tempA[5]]-AMPM[tempB[5]])+(Number(clockA[0])+Number(clockA[1])/60-Number(clockB[0])-Number(clockB[1])/60)/24;
        });
        //console.log(eventRowsToRender);
      } else if(this.state.sortBy==="Total Count"){
        eventRowsToRender.sort(function(a, b){
          return b["Total Count"]-a["Total Count"]
        })
      }
    }

      // var eventInfoToRender = (
      //   <h3 className="main_question">
      //     <b>{this.state.eventStartEnd}</b><br></br><br></br>
      //     COVID-19 Test Results Due: <b>{this.state.eventRsvpDueDate}</b><br></br>
      //   </h3>
      // );

    //console.log(eventRowsToRender);
    //now a 2d array of the table.

    const handleDragStart = e =>{
      const {id} = e.target;
      const idx = this.state.columnNames.indexOf(id)
      e.dataTransfer.setData("colIdx", idx);
    };

    const handleDragOver = e =>{
      e.preventDefault();
    }

    const handleDragEnter = e =>{
      const {id} = e.target;
      this.setState({dragOver: id});
    }

    const handleOnDrop = e =>{

      const{id} = e.target;
      console.log(id);
      const droppedColIdx = this.state.columnNames.indexOf(id);
      const draggedColIdx = e.dataTransfer.getData("colIdx");
      const tempCols = [...this.state.columnNames];
      console.log(droppedColIdx);
      console.log(draggedColIdx);
      tempCols[draggedColIdx] = this.state.columnNames[droppedColIdx];
      tempCols[droppedColIdx] = this.state.columnNames[draggedColIdx];
      console.log(tempCols);
      this.setState({columnNames: tempCols})
      this.updateOrdering(tempCols);

      //console.log(columnNames);
    }
    // let test = eventRowsToRender.map(row=>(
    //   row ? 
    //   <tr key = {row.id}>
    //     {Object.entries(row).map(([k, v], idx)=>(
    //       <td key={[k, v]} dragOver={columnNames[idx]===dragOver}>
    //         {row[columnNames[idx]]}
    //       </td>
    //     ))}
    //   </tr> : null
    // ))
    // console.log(test);
    /*
    Returns the final page design. The Classmap maintains a mapping between column names and their class for css styling.
    */
    let columnHandlers = {"Total Count": this.handleStartTimeButton};
    let classMap = {"Tested COVID-19 Negative":"result_negative", "Tested COVID-19 Positive":"result_positive", "Test Results Not Submitted": "result_none"};
    return (

      <div className="wrapper_centering">
        <div className="container_centering">
          <div className="container">
              <div className="row justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="main_title_1">
                    <br/><br/>
                    <h3 className="font-mont">All Events With Guests</h3>
                    <br></br>
                    <h3 className="main_question"> 
                    <table id = "dashboard">
                      <thead>
                        <tr>
                          {this.state.columnNames.map(col => (
                            <th 
                              id = {col} 
                              key = {col} 
                              draggable 
                              onDragStart={handleDragStart} 
                              onDragOver = {handleDragOver}
                              onDrop = {handleOnDrop}
                              onDragEnter = {handleDragEnter}
                              dragOver = {col===this.state.dragOver}
                              onClick = {columnHandlers[col]}>
                              {col}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                          {eventRowsToRender.map(row => (
                          row ? 
                          <tr key = {row.id}>
                            {Object.entries(row).map(([k, v], idx)=> {
                              return(
                                <td className = {classMap[this.state.columnNames[idx]]} key={[k, v]} dragOver={this.state.columnNames[idx]===this.state.dragOver}>
                                  {row[this.state.columnNames[idx]]}
                                </td>
                              )
                            }
                            )}
                          </tr> : null
                        ))}
                      </tbody>
                    </table>
                    </h3>
                    <br></br>
                    <p><strong><a className='test-link' href='../'>&larr; Back to home</a></strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      );
  }
}; 
export default OwnerDashboard