import React from 'react';
//import Button from 'react-bootstrap/Button'

class HostEvent extends React.Component {

  constructor(props) {
    super(props);

    //localstorage to keep the info upon page refresh :)

    this.state = {
      userProfile: JSON.parse(localStorage.getItem('userProfile')) || null,
      eventId: 0,   
      eventName: "Event Attendees",
      eventStartEnd: null,
      eventRsvpDueDate: null,
      testsError: null,
      testsLoaded: false,
      testItems: [],
    }

    this.userProfile = { // default test profile
      givenName: "Test",
      familyName: "User",
      email: "test@user.com",
      imageUrl: "",
      token: ""
    };


    if (window.location.host.startsWith("localhost")) {
      this.baseURL = "http://localhost:8080";
    } else if(window.location.host.startsWith("deacon-testing")) {
      this.baseURL = "https://deacon-testing.ue.r.appspot.com";
    } else {
      this.baseURL = "https://deacon.ue.r.appspot.com";
    }

    if (this.props.userProfile!=null) {

      this.state = { userProfile: this.props.userProfile };
      localStorage.setItem('userProfile', JSON.stringify(this.props.userProfile));

      this.userProfile = this.props.userProfile;

    } else if (this.state.userProfile!=null) {

      this.userProfile = this.state.userProfile;

    }

    console.log("this.userProfile.token: "+this.userProfile.token);

  }

  fetchEventInfo(eventId) {

    var formBody = new URLSearchParams();
    formBody.append("host_email", this.userProfile.email);
    formBody.append("token", this.userProfile.token);
    formBody.append("event_id", eventId);

    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
      body: formBody
    }

    // https://reactjs.org/docs/faq-ajax.html

    fetch(this.baseURL + '/get-host-event-info', requestOptions)
      .then(res => res.json())
      .then(
        (results) => {

          console.log("results: " + JSON.stringify(results));

          if (results.error) {
            //console.log("results.error: "+ results.error);
          } else {
            this.setState({
              eventName: results.event_name,
              eventStartEnd: results.event_start_end,
              eventRsvpDueDate: results.event_rsvp_due_date
            });
          }

        },
        (error) => {
          console.log("Error:"+error);
        }
      );

  }

  componentDidMount() {

    const { match: { params } } = this.props;

    var eventId = params.id;
    console.log('eventId', eventId);

    this.setState({eventId: eventId});


    // get event info
    this.fetchEventInfo(eventId);

    // get test results

    var formBody = new URLSearchParams();
    formBody.append("host_email", this.userProfile.email);
    formBody.append("token", this.userProfile.token);
    formBody.append("event_id", eventId);

    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
      body: formBody
    }

    // https://reactjs.org/docs/faq-ajax.html

    fetch(this.baseURL + '/get-guest-covid-tests', requestOptions)
      .then(res => res.json())
      .then(
        (results) => {

          console.log("results: " + JSON.stringify(results));

          if (results.error) {
            //console.log("results.error: "+ results.error);
            this.setState({
              testsLoaded: false,
              testsError: results.error
            });
          } else {
            this.setState({
              testsLoaded: true,
              testsItems: results.guestTests
            });
          }

        },
        (error) => {
          console.log("Error:"+error);
          this.setState({
            testsLoaded: false,
            testsError: error
          });
        }
      );


  
  }

  render () {

    var formActionLink = this.baseURL + "/get-covid-file";

    // set up table to render

    let testsToRender = '';
    //console.log(this.state.testsItems);
    var countNegatives = 0, countPositives = 0, countNotSubmitted = 0;

    if (this.state.testsError) {
      // error (e.g. token expired)
      testsToRender = ( <h3 className="main_question">
        Please <a className="test-link" href="/host">sign in again</a> to view this page.
        </h3> );

    } else if (!this.state.testsItems || this.state.testsItems.length===0) {
      // no upcoming events
      testsToRender = ( <h3 className="main_question">No attendees for this event can be shown.</h3> );

    } else if (this.state.testsItems && this.state.testsItems.length > 0) {

      let testRowsToRender = this.state.testsItems.map((row, index) => {
        var { firstName, lastName, email, address, phone, 
          testId, testDate, testResult, fileName } = row //destructuring

        if (testDate==null) {
          testDate = "Not Submitted";
        }

        var cellFormat = "result_none";
        if (testResult==null) {
          testResult = "Not Submitted";
          countNotSubmitted++;
        } else if (testResult==="Negative") {
          cellFormat = "result_negative";
          countNegatives++;
        } else if (testResult==="Positive") {
          cellFormat = "result_positive";
          countPositives++;
        }

        var downloadForm = "Not Submitted";
        if (cellFormat!=="result_none") {
          downloadForm = (<form action={formActionLink} method="POST" target="_blank" autoComplete="off">
                            <input readOnly id="host_email" name="host_email" type="hidden" value={this.userProfile.email}/>
                            <input readOnly id="token" name="token" type="hidden" value={this.userProfile.token}/>
                            <input readOnly id="event_id" name="event_id" type="hidden" value={this.state.eventId}/>
                            <input readOnly id="test_id" name="test_id" type="hidden" value={testId}/>
                            <input readOnly id="file_name" name="file_name" type="hidden" value={fileName}/>
                            <button type="submit" name="download" className="submit">
                              <svg className="bi bi-search" width="1.2em" height="1.2em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                                <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                              </svg>
                            </button>
                          </form>);
        } 


        return (
          <tr key={email}>
            <td>{firstName}</td>
            <td>{lastName}</td>
            <td>{email}</td>
            <td>{address}</td>
            <td>{phone}</td>
            <td className={cellFormat}>{testDate}</td>
            <td className={cellFormat}>{testResult}</td>
            <td className={cellFormat}>{downloadForm}</td>
          </tr>
        )
      });


      testsToRender = (<h3 className="main_question"> 
        <table id="dashboard">
          <tbody>
            <tr><th>First Name</th><th>Last Name</th><th>Email</th><th>Address</th>
            <th>Phone</th><th>Test Date</th><th>Test Result</th><th>View Doc</th>
            </tr>
            {testRowsToRender} 
          </tbody>
        </table> 
        </h3>);

    }

    var countsToRender = "";
    if (countNegatives + countPositives + countNotSubmitted > 0) {
      countsToRender = (
        <h3 className="main_question"> 
          <table id="dashboard">
            <tbody>
              <tr className="big_font"><th>Total Count</th><th>Tested COVID-19 Negative</th><th>Tested COVID-19 Positive</th><th>Test Results Not Submitted</th></tr>
              <tr className="big_number">
                <td>{countPositives + countNegatives + countNotSubmitted}</td>
                <td className="result_negative">{countNegatives}</td>
                <td className="result_positive">{countPositives}</td>
                <td className="result_none">{countNotSubmitted}</td>
              </tr>
            </tbody>
          </table> 
        </h3>
      );
    }

    var eventInfoToRender = (
      <h3 className="main_question">
        <b>{this.state.eventStartEnd}</b><br></br><br></br>
        COVID-19 Test Results Due: <b>{this.state.eventRsvpDueDate}</b><br></br>
      </h3>
    );

    if (!this.state.eventRsvpDueDate) {
      eventInfoToRender = "";
    }

    // reveal update form and hide question
    function showForm() {
      document.getElementById("update_question").style.display = 'none';
      document.getElementById("update_form").style.display = 'block';
    }

    let currentHost = window.location.protocol + "//" + window.location.host;
    let eventLocation = "Deacon";
    let userEmail = this.userProfile.email;

    let updateFormActionLink = this.baseURL + "/update-guest-list"

    return (

      <div className="wrapper_centering">
        <div className="container_centering">
          <div className="container">
              <div className="row justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="main_title_1">
                    <br/><br/>
                    <h3 className="font-mont">{this.state.eventName}</h3>
                    {eventInfoToRender}
                    <br></br>
                    <h3 className="main_question" id="update_question">
                      Need to update your guest list? <a className="test-link" href="#ShowForm" onClick={() => {showForm();}}>Upload an updated CSV</a>.
                    </h3>
                    <form action={updateFormActionLink} id="update_form" method="POST" autoComplete="off" encType="multipart/form-data" style={{display:'none'}}>
                  
                      <input readOnly id="website" name="website" type="text" value={"demo-deacon.groveflow.com/host-event/"+this.state.eventId}/>
                      {/*<!-- Leave for security protection, read docs for details -->*/}

                      <input readOnly id="redirect_url" name="redirect_url" type="hidden" value={currentHost+"/host-event-updated"}/>
                      <input readOnly id="event_location" name="event_location" type="hidden" value={eventLocation}/>
                      <input readOnly id="host_email" name="host_email" type="hidden" value={userEmail}/>
                      
                      <div id="middle_wizard">
                        <h3 className="main_question">Please download this <a className="test-link" href="/deacon_event_attendee_template.csv" download>CSV file template</a> to set up your guest list. Then upload your CSV guest list here:</h3>
                        <input type="file" accept=".csv" name="guest_csv_file" id="guest_csv_file" required/>

                        {/*Hidden field to pass event id to server*/}
                        <input readOnly type="hidden" name="event_id" id="event_id" value={this.state.eventId}/>
                      </div>

                      <div id="bottom-wizard">
                        <button type="submit" name="process" className="submit">Submit</button>
                      </div>
                    </form>
                    <br></br>
                    {countsToRender}
                    <br></br>
                    {testsToRender}
                    <br></br>
                    <strong><a className='test-link' href='/host-input'>&larr; Back to dashboard</a></strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

export default HostEvent 