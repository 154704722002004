import React from 'react';

class HostLandingPage extends React.Component {

  render () {
    return (

      <div className="wrapper_centering">
        <div className="container_centering">
          <div className="container">
              <div className="row">
                <div className="col-xl-7 col-lg-7 align-items-center">
                  <div className="main_title_1">
                    <a href="https://www.thedeaconphl.com/" target="_blank" rel="noopener noreferrer">
                      <img src="img/deacon-white-bg.png" alt="The Deacon" style={{marginRight: 0, marginBottom: 0}}/>
                    </a>
                    <br></br><br></br>
                    <h3 className="font-mont">Event Host</h3>
                    <p className="font-mont">Set up events during the pandemic. Attendees can RSVP with COVID-19 test results before the event.</p>

                  </div>
                  <br></br>
                  <a className="test-link font-mont" href="/">Are you an event attendee?</a>

                </div>

                <div className="col-xl-5 col-lg-5" style={{paddingLeft:40}}>
                  {/*<!-- entry point to app -->*/}
                  {this.props.children}

                </div>
              </div>
            </div>
          </div>

      </div>

    );
  }
}

export default HostLandingPage 