import React from 'react';

class Input extends React.Component {

  constructor(props) {
    super(props);

    //localstorage to keep the info upon page refresh :)

    this.state = {
      userProfile: JSON.parse(localStorage.getItem('userProfile')) || null,
      eventsError: null,
      eventsLoaded: false,
      eventsItems: []
    }

    this.userProfile = { // default test profile
      givenName: "Test",
      familyName: "User",
      email: "test@user.com",
      imageUrl: "",
      token: ""
    };

    if (window.location.host.startsWith("localhost")) {
      this.baseURL = "http://localhost:8080";
    } else if(window.location.host.startsWith("deacon-testing")) {
      this.baseURL = "https://deacon-testing.ue.r.appspot.com";
    } else {
      this.baseURL = "https://deacon.ue.r.appspot.com";
    }

    if (this.props.userProfile!=null) {

      this.state = { userProfile: this.props.userProfile };
      localStorage.setItem('userProfile', JSON.stringify(this.props.userProfile));

      this.userProfile = this.props.userProfile;

    } else if (this.state.userProfile!=null) {

      this.userProfile = this.state.userProfile;

    }
    console.log("this.userProfile.token: "+this.userProfile.token);

  }


  // get events for email address and token
  componentDidMount() {

    var formBody = new URLSearchParams();
    formBody.append("email", this.userProfile.email);
    formBody.append("token", this.userProfile.token);

    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
      body: formBody
    }


    // https://reactjs.org/docs/faq-ajax.html

    fetch(this.baseURL + '/get-guest-events', requestOptions)
      .then(res => res.json())
      .then(
        (results) => {

          console.log("results: " + JSON.stringify(results));

          if (results.error) {
            //console.log("results.error: "+ results.error);
            this.setState({
              eventsLoaded: false,
              eventsError: results.error
            });
          } else {
            this.setState({
              eventsLoaded: true,
              eventsItems: results.upcomingEvents
            });
          }

        },
        (error) => {
          console.log("Error:"+error);
          this.setState({
            eventsLoaded: false,
            eventsError: error
          });
        }
      );


  }


  render() {
    var userGivenName, userFamilyName, userEmail;

    console.log("this.userProfile: " + JSON.stringify(this.userProfile));

    userGivenName = this.userProfile.givenName;
    userFamilyName = this.userProfile.familyName;
    userEmail = this.userProfile.email;
    //userImageUrl = this.userProfile.userImageUrl;

    var currentHost = window.location.protocol + "//" + window.location.host;
    var formActionLink = this.baseURL + "/add-covid-test";

    var testDate = new Date("01/01/2020").toISOString().substr(0,10);

    // displays all parts of form
    function showForm() {
      document.getElementById("test_recommendations").style.display = 'block';
      document.getElementById("form_questions").style.display = 'block';
      document.getElementById("submit_area").style.display = 'block';
    }

    // set up next event to render
    let upcomingEventToRender = '';
    
    if (this.state.eventsError) {
      // error (e.g. token expired)
      // to avoid confusion in case of backend error, just hide this.
      /*
      upcomingEventToRender = ( <div>
        Please <a className="test-link" href="/">sign in again</a> to see the next event you're attending.
        </div> );
     */


    } else if (!this.state.eventsItems || this.state.eventsItems.length===0) {
      // no upcoming events
      upcomingEventToRender = ( <div> Our records show that you're not on the list for an upcoming event. 
        If this is incorrect, please <a className="test-link" href="/">sign in again</a> with a different email address. 
        Otherwise, <a className="test-link" href="#ShowForm" onClick={() => {showForm(); document.getElementById("upcoming_event").style.display = 'none';}}>
          continue to the submission form</a>.
      </div> );

    } else if (this.state.eventsItems && this.state.eventsItems.length > 0) {

      // display form since it is hidden by default
      showForm();

      var upcomingEvent = this.state.eventsItems[0];

      // get the next event
      upcomingEventToRender = (
        <div>
        We have you on the list to attend <b>{upcomingEvent.eventName}</b> on <b>{upcomingEvent.eventStartTime}</b>.
        <br></br><br></br>
        For everyone's safety, please complete the form below by <b>{upcomingEvent.rsvpDueDate}</b>. Thank you!
        <br></br><br></br>
        </div>
      );
    }

    return (

      <div>
        <br/><br/><br/>

        <div className="wrapper_centering">
          <div className="container_centering">
            <div className="container">

              <form action={formActionLink} id="wrapped" method="POST" autoComplete="off" encType="multipart/form-data">

                <input readOnly id="website" name="website" type="text" value="demo-deacon.groveflow.com/input"/>
                    {/*<!-- Leave for security protection, read docs for details -->*/}

                <input readOnly id="redirect_url" name="redirect_url" type="hidden" value={currentHost+"/input-submitted"}/>

                <input readOnly id="email" name="email" type="hidden" value={userEmail}/>
                <input readOnly id="first_name" name="first_name" type="hidden" value={userGivenName}/>
                <input readOnly id="last_name" name="last_name" type="hidden" value={userFamilyName}/>

                <div id="middle-wizard">

                  <div className="submit">

                    <h3 className="main_question">Hi {userGivenName}! <br></br><br></br>  

                      <div id="upcoming_event">
                        {upcomingEventToRender}
                        <br></br>
                      </div>

                      <div id="test_recommendations" style={{display:'none'}}>
                        <p style={{border:"1px", borderStyle:"dotted", borderColor:"white", padding: "1em"}}>
                          Because it can take a few days to return test results, we recommend that 
                          you <a className="test-link font-mont" href="https://www.pixel.labcorp.com/at-home-test-kits/covid-19-test">
                            request a COVID-19 test shipped to you today
                          </a> (select "Congregate Setting" for question 3). 
                          Or <a className="test-link font-mont" href="https://www.phila.gov/covid-testing-sites/#/">
                            plan for in-person testing in Philadelphia
                          </a> (e.g. at <a className="test-link font-mont" href="https://www.riteaid.com/pharmacy/services/covid-19-testing">
                            Rite-Aid </a> or <a className="test-link font-mont" href="https://www.medexpress.com/">MedExpress</a>).  
                        </p>
                      </div>

                      {/* TODO: add info if we already have a test result */}

                    </h3>
                    <br></br><br></br>

                    <div id="form_questions" style={{display:'none'}}>
                      <h3 className="main_question">1. When were you most recently tested for COVID-19?</h3>
                      <input type="date" name="test_date" id="test_date" className="form-control" style={{height:"50px"}} defaultValue={testDate} required></input>

                      <br></br><br></br>

                      <h3 className="main_question">2. What was your COVID-19 test result?</h3>

                      <div className="review_block_numbers">
                        <ul className="clearfix">
                          <li>
                            <div className="container_numbers">
                              <input type="radio" id="test_result_positive" name="test_result" className="required" value="Positive"/>
                              <label className="radio very_bad" htmlFor="test_result_positive">Positive</label>
                            </div>
                          </li>
                          <li>
                            <div className="container_numbers">
                              <input type="radio" id="test_result_negative" name="test_result" className="required" value="Negative"/>
                              <label className="radio very_good" htmlFor="test_result_negative">Negative</label>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <br></br><br></br>


                      <h3 className="main_question">3. Please attach documentation of your COVID-19 test result (e.g. file image):</h3>

                      <input type="file" name="uploaded_file" id="uploaded_file" required/>

                      <br/><br/><br/>
                    </div>

                  </div>

                </div>
                {/*<!-- /middle-wizard -->*/}

                <div id="submit_area" style={{display:'none'}}>
                  <div id="bottom-wizard">
                    <button type="submit" name="process" className="submit">Submit</button>
                  </div>
                  <br></br>
                  <h3 style={{textAlign: "right"}} className="main_question">
                    <i>By submitting this form, you attest that the provided information is true and accurate.</i>
                  </h3>
                </div>
                
              </form>
            </div>

          </div>
          {/*<!-- /container_centering -->*/}
        </div> 
      </div>

    );
  }
}

export default Input 