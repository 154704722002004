import React, { Component } from 'react';

class HostInputSubmitted extends Component {

  render () {
    return (

      <div className="wrapper_centering">
        <div className="container_centering">
          <div className="container">
              <div className="row justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="main_title_1">
                    <br/><br/>
                    <h3>New event created!</h3>
                    <p>
                        Please direct your guests to <strong><a href='../'>deacon-safety.groveflow.com</a></strong>, where they can upload test results. 
                        To see these results, ensure that your guests sign in with the email provided on your guest list. <br></br>
                        <a className='test-link' href='#CopyLink' onClick={() => {navigator.clipboard.writeText("deacon-safety.groveflow.com")}}>Copy link to clipboard</a>
                    </p>
                    <br></br>
                    <br></br>
                    <p>If you would like to see your dashboard in the future, bookmark this link: <br></br>
                     <strong><a href='/host'>deacon-safety.groveflow.com/host</a></strong> <br></br>
                     <a className='test-link' href='#CopyLink' onClick={() => {navigator.clipboard.writeText("deacon-safety.groveflow.com/host")}}>Copy link to clipboard</a>
                    </p>
                    <br></br>
                    <br></br>
                    <p><strong><a className='test-link' href='../'>&larr; Back to home</a></strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>

    );
  }
}

export default HostInputSubmitted 