import React from 'react';

class HostInput extends React.Component {

  // userProfile, baseURL

  constructor(props) {
    super(props);

    //localstorage to keep the info upon page refresh :)

    this.state = {
      userProfile: JSON.parse(localStorage.getItem('userProfile')) || null,
      eventsError: null,
      eventsLoaded: false,
      eventsItems: []
    }

    this.userProfile = { // default test profile
      givenName: "",
      familyName: "User",
      email: "test@user.com",
      imageUrl: "",
      token: ""
    };


    if (window.location.host.startsWith("localhost")) {
      this.baseURL = "http://localhost:8080";
    } else if(window.location.host.startsWith("deacon-testing")) {
      this.baseURL = "https://deacon-testing.ue.r.appspot.com";
    } else {
      this.baseURL = "https://deacon.ue.r.appspot.com";
    }

    if (this.props.userProfile!=null) {

      this.state = { userProfile: this.props.userProfile };
      localStorage.setItem('userProfile', JSON.stringify(this.props.userProfile));

      this.userProfile = this.props.userProfile;

    } else if (this.state.userProfile!=null) {

      this.userProfile = this.state.userProfile;

    }

    console.log("this.userProfile.token: "+this.userProfile.token);

  }


  // get events for email address and token
  componentDidMount() {

    var formBody = new URLSearchParams();
    formBody.append("host_email", this.userProfile.email);
    formBody.append("token", this.userProfile.token);

    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
      body: formBody
    }


    // https://reactjs.org/docs/faq-ajax.html

    fetch(this.baseURL + '/get-host-events', requestOptions)
      .then(res => res.json())
      .then(
        (results) => {

          console.log("results: " + JSON.stringify(results));

          if (results.error) {
            //console.log("results.error: "+ results.error);
            this.setState({
              eventsLoaded: false,
              eventsError: results.error
            });
          } else {
            this.setState({
              eventsLoaded: true,
              eventsItems: results.upcomingEvents
            });
          }

        },
        (error) => {
          console.log("Error:"+error);
          this.setState({
            eventsLoaded: false,
            eventsError: error
          });
        }
      );


  }

  render() {

    console.log("this.userProfile: " + JSON.stringify(this.userProfile));

    var userGivenName = this.userProfile.givenName;
    //var userFamilyName = this.userProfile.familyName;
    var userEmail = this.userProfile.email;
    //var userImageUrl = this.userProfile.userImageUrl;
    var eventLocation = "Deacon"; // relates to event time zone

    // default values for testing
    var eventName = "Example Event";

    var todayDate = new Date();
    var oneMonthDate = new Date(todayDate.setMonth(todayDate.getMonth()+1));

    var eventDate = oneMonthDate.toISOString().substr(0,10);
    var eventStartTime = "17:00:00";
    var eventEndTime = "20:00:00";

    let isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
    //let isSafari = false;

    if (isSafari) {
      // code to handle Safari UI weirdness
      eventStartTime = "05:00 PM";
      eventEndTime = "08:00 PM";
    }

    // default RSVP date to 2 days before
    oneMonthDate.setDate(oneMonthDate.getDate()-2);
    var rsvpDueDate = oneMonthDate.toISOString().substr(0,10);


    var currentHost = window.location.protocol + "//" + window.location.host;

    var formActionLink = this.baseURL + "/add-event";

    // set up events to render

    let eventsToRender = '';
    
    if (this.state.eventsError) {
      // error (e.g. token expired)
      eventsToRender = ( <h3 className="main_question">
        Please <a className="test-link" href="/host">sign in again</a> to see any events you're hosting.
        </h3> );

    } else if (!this.state.eventsItems || this.state.eventsItems.length===0) {
      // no upcoming events
      eventsToRender = ( <h3 className="main_question">Our records show that you're not hosting any upcoming events.</h3> );

    } else if (this.state.eventsItems && this.state.eventsItems.length > 0) {

      var upcomingEvents = this.state.eventsItems;

      // list upcoming events
      var eventsToRenderList = [];
      for (var i=0; i < upcomingEvents.length; i++) {
        var id = upcomingEvents[i].id;
        eventName = upcomingEvents[i].eventName;
        eventStartTime = upcomingEvents[i].eventStartTime;

        eventsToRenderList.push((<li key={id}>
          <a className="test-link" href={'/host-event/' + id}>{eventName} - {eventStartTime}</a>
          </li>));
      }
      var prefixText = "You're hosting these upcoming events:";
      if (upcomingEvents.length===1) {
        prefixText = "You're hosting this upcoming event:";
      }
      eventsToRender = (<h3 className="main_question"> 
        {prefixText}
        <ul className="events-list">
        {eventsToRenderList} 
        </ul> </h3>);


    }
  

    return (

      <div>
        <br/><br/><br/>

        <div className="wrapper_centering">
            <div className="container_centering">
                <div className="container">

                  <form action={formActionLink} id="form" method="POST" autoComplete="off" encType="multipart/form-data">
                  
                    <input readOnly id="website" name="website" type="text" value="demo-deacon.groveflow.com/host-input"/>
                    {/*<!-- Leave for security protection, read docs for details -->*/}

                    <input readOnly id="redirect_url" name="redirect_url" type="hidden" value={currentHost+"/host-input-submitted"}/>
                    <input readOnly id="event_location" name="event_location" type="hidden" value={eventLocation}/>
                    <input readOnly id="host_email" name="host_email" type="hidden" value={userEmail}/>

                    <div id="middle-wizard">

                      <div className="submit">

                        <h3 className="main_question">Hi {userGivenName}!</h3>
                        <br></br>
                        {eventsToRender}
                        <br></br>

                        <h3 className="main_question">Complete the form below to host a new event.</h3>


                        <div className="form-group">
                            <label htmlFor="event_name">Event Name</label>
                            <textarea rows="1" name="event_name" id="event_name" className="form-control" style={{height:"50px"}} defaultValue={eventName} required></textarea>
                        </div>

                        <div className="review_block_numbers">
                          <ul className="clearfix">
                            <li>
                              <div className="form-group">
                                <label htmlFor="event_date">Event Date</label>
                                <input type="date" name="event_date" id="event_date" className="form-control" style={{height:"50px"}} defaultValue={eventDate} required></input>
                              </div>
                            </li>
                            <li>
                              <div className="form-group">
                                <label htmlFor="event_start_time">Event Start Time</label>
                                <input type="time" name="event_start_time" id="event_start_time" className="form-control" style={{height:"50px"}} defaultValue={eventStartTime} required></input>
                              </div>
                            </li>
                            <li>
                              <div className="form-group">
                                <label htmlFor="event_end_time">Event End Time</label>
                                <input type="time" name="event_end_time" id="event_end_time" className="form-control" style={{height:"50px"}} defaultValue={eventEndTime} required></input>
                              </div>
                            </li>
                          </ul>
                        </div>


                        <div className="review_block_numbers">
                          <ul className="clearfix">
                            <li>
                              <div className="form-group">
                                <label htmlFor="rsvp_due_date">RSVP Due Date for COVID-19 Test Results</label>
                                <input type="date" name="rsvp_due_date" id="rsvp_due_date" className="form-control" style={{height:"50px"}} defaultValue={rsvpDueDate} required></input>
                              </div>
                            </li>

                          </ul>
                        </div>

                        <br/><br/>

                        <h3 className="main_question">Please download this <a className="test-link" href="deacon_event_attendee_template.csv" download>CSV file template</a> to set up your guest list. Then upload your CSV guest list here:</h3>

                        <input type="file" accept=".csv" name="guest_csv_file" id="guest_csv_file" required/>

                        <br/><br/><br/>

                      </div>

                    </div>
                    {/*<!-- /middle-wizard -->*/}

                    <div id="bottom-wizard">
                      <button type="submit" name="process" className="submit">Submit</button>
                    </div>
                    {/*<!-- /bottom-wizard -->*/}

                  </form>
              </div>

            </div>
            {/*<!-- /container_centering -->*/}
      </div> </div>

    );
  }
}

export default HostInput 